import jsPDF from "jspdf";
import "jspdf-autotable";
import { addHeaders, addFooters, marges2 } from "print/entete.js";
import { numberWithSpace,datefr } from "@/utils/functions.js";

export function doc_template(store,detailheaders, data_list,doctype,
  tier,foot,lettre,notes,title,cmpt=null,info=false,company="",intro=null,f="p",letter_h,desc=true,comment=null) {
  let head = [];
  let columns = [];
  let items = [];
  let item;
  
  var doc = new jsPDF(f);
  let marges = marges2(doc, store);
  //data formating
  for (let index = 0; index < data_list.length; index++) {
    item = {};
    detailheaders.forEach((elm) => {
      if (elm.value == "no") {
        item[elm.value] = index + 1;
      } else if (elm.slot == "date") {     
        item[elm.value] = datefr(data_list[index][elm.value],false,"numeric");         
      } else if (elm.slot == "cur22") {     
        item[elm.value] = numberWithSpace(
          parseFloat(data_list[index][elm.value]).toFixed(2)
        );         
      } else if (elm.slot == "cur" && data_list[index][elm.value]) {
        item[elm.value] = numberWithSpace(
          parseFloat(data_list[index][elm.value]).toFixed(2)
        );
      } else if (elm.slot == "cur3" && data_list[index][elm.value]) {
        item[elm.value] = numberWithSpace(
          parseFloat(data_list[index][elm.value]).toFixed(3)
        );        
      } else if (elm.slot == "qte" && data_list[index][elm.value]) {
        item[elm.value] = numberWithSpace(
          parseFloat(data_list[index][elm.value]).toFixed(data_list[index]['decimal']!=null ? data_list[index]['decimal'] : 2)
        );        
      } else if (data_list[index][elm.value])
        item[elm.value] = data_list[index][elm.value];   
        if (item[elm.value] && typeof item[elm.value]=='string')
          item[elm.value] = item[elm.value].replace(/μ/g, "\u00B5");       
    });
    item.ht = numberWithSpace(
      parseFloat(
        data_list[index].pu * data_list[index].qte
      ).toFixed(2)
    );
    if (desc)
    item.label = data_list[index].label +
      (data_list[index].description ? "\n" + data_list[index].description : "");
       
    items.push(item);
  }  
  let columnStyles = {};
 
  columns = [];
  columns.push({ dataKey: "lib" });
  columns.push({ dataKey: "val" });
 
  doc.autoTable({
    margin: { left: marges.margin_left },
    startY:marges.margin_top + marges.l_h+(info ? 13 : 8), 
    body: tier,
    columnStyles: {
      0: { halign: "left", cellWidth: 25 },
      1: { halign: "left", cellWidth: "auto" },     
    },
    columns: columns,
    theme: "plain",
    styles: {
      valign: "top",
      fillColor: [255, 255, 255],
      textColor: [128, 128, 128],
      cellPadding: 0,
    },
    tableWidth: 60,
  });
  let posmax=doc.lastAutoTable.finalY
  doc.autoTable({
    margin: { left: marges.margin_left+95 , },
    startY:marges.margin_top + marges.l_h+(info ? 5 : 0), 
    body: doctype,
    
    columnStyles: {
      0: { halign: "left", cellWidth: 50 },
      1: { halign: "left", cellWidth: "auto" },     
    },
    columns: columns,
    theme: "plain",
    styles: {
      valign: "top",
      fillColor: [255, 255, 255],
      textColor: [128, 128, 128],
      cellPadding: 0,
    },
    tableWidth: "auto",
  });
  if (doc.lastAutoTable.finalY>posmax) posmax=doc.lastAutoTable.finalY
  let posintro=posmax
if (intro) {
let t=[]
columns = [];
  columns.push({ dataKey: "lib" });
  t.push({
    lib: {
      content: intro,
      styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },     
    },
  });
  doc.autoTable({
    margin: { left: marges.margin_left+15 },
    startY:posmax + 5,    
    body: t,
    columnStyles: {      
      0: { halign: "left", cellWidth: "auto" },     
    },
    columns: columns,
    theme: "plain",
    styles: {
      valign: "middle",
      fillColor: [255, 255, 255],
      textColor: [128, 128, 128],
      cellPadding: 0,
    },
    tableWidth: "auto",
  });
  if (doc.lastAutoTable.finalY>posintro) posintro=doc.lastAutoTable.finalY
}
  columns = [];
  columnStyles = {};

  item = {};
  detailheaders.filter(elm=>elm.hiden!=true).forEach((element) => {
    head.push({
      content: element.text,
      styles: {
        halign:
          element.align == "end"
            ? "right"
            : element.align == "center"
              ? "center"
              : "left",
        fontSize: 8
      },
    });

    columns.push({ dataKey: element.value });
    columnStyles[element.value] = {
      cellWidth: element.width,
      valign: "middle",
      halign:
        element.align == "end"
          ? "right"
          : element.align == "center"
            ? "center"
            : "left",
      fontSize: 8

    };
  });

  doc.autoTable({
    margin: {     
      top:marges.margin_top + marges.l_h+5,
      bottom: marges.margin_bottom + marges.p_h,
    },    
    startY:posintro + 5,
    didDrawPage: function(data) {
      // Reseting top margin. The change will be reflected only after print the first page.
      data.settings.margin.top = posmax + 5 ;//marges.margin_top + marges.l_h;
    },
    headStyles: {
      halign: "center",
      valign: "middle",
      fillColor: [128, 128, 128],
    },
    columnStyles: columnStyles,
    head: [head],
    body: items,
    columns: columns,
    showHead: "everyPage",
    showFoot: "everyPage",    
    theme: "striped",
  });

  columns = [];
  columns.push({ dataKey: "libelle" });
  columns.push({ dataKey: "value" });
  let pos=doc.lastAutoTable.finalY+2 
 if ((f=="p" && pos> 240) || (f=="l" && pos>150)) {
  doc.addPage()
  pos=posmax+5
  // doc.autoTable({
  //   margin: {     
  //     top:marges.margin_top + marges.l_h+5,
  //     bottom: marges.margin_bottom + marges.p_h,
  //   },    
  //   startY:posmax+5,
  //   headStyles: {
  //     halign: "center",
  //     valign: "middle",
  //     fillColor: [128, 128, 128],
  //   },
  //   columnStyles: columnStyles,
  //   head: [head],      
  //   showHead: "everyPage",
  //   showFoot: "everyPage",    
  //   theme: "striped",
  // });
 }
  doc.autoTable({
    margin: { left: marges.margin_left + 95 ,},
    startY:pos,
    body: foot,
    columnStyles: {
      0: { halign: "left", cellWidth: "auto", fontSize: 10 },
      1: {
        halign: "right",
        cellWidth: 30,
        fontSize: 10,
      },
    },
    columns: columns,
    theme: "plain",
    styles: {
      valign: "middle",
      fontSize: 12,
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      cellPadding: {right:2,bottom:0.5},
    },
    tableWidth: "auto",
  });
 //lettre
 if (lettre)
 if (lettre.length>0) {
  pos=doc.lastAutoTable.finalY+2 
  if ((f=="p" && pos> 240) || (f=="l" && pos>150) && letter_h) {
    doc.addPage()
    pos=posmax+5
  }
  columns = [];
  head = [];
  let w="auto"
  if (letter_h) {
    letter_h.forEach(element => {
      head.push({content:element.head,  styles: {
        halign:element.halign,          
        fontSize: 8
      },})
      columns.push({ dataKey: element.datakey });
    }); 
    w=100  
  } else columns.push({ dataKey: "libelle" }); 
 doc.autoTable({
  margin: { left: marges.margin_left,bottom: marges.margin_bottom + marges.p_h, },
  startY:pos,
  didDrawPage: function(data) {
    // Reseting top margin. The change will be reflected only after print the first page.
    data.settings.margin.top = posmax + 5 ;//marges.margin_top + marges.l_h;
  },
  body: lettre,
  columnStyles: {
    0: { halign: "left", cellWidth: "auto" },
    1: { halign: "right", cellWidth: "auto" },         
  },  
  head:[head],
  columns: columns,
  theme: "striped",
  styles: {
    valign: "middle",
    fontSize: 11,
    fillColor: [255, 255, 255],
    textColor: [0, 0, 0],
    cellPadding: 0.5,        
  },
  showHead: "everyPage",
  tableWidth: w,
});
 }
  //note
  if (notes) {
    columns = [];
    columns.push({ dataKey: "note" });
    let list = [];
    list.push({ note: notes });
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 5,
      head: [["Notes :"]],
      columns: columns,
      columnStyles: {
        0: { cellWidth: "auto" },
      },
      body: list,
      theme: "plain",
      headStyles: {
        halign: "left",
        valign: "middle",
        fillColor: [255, 255, 255],
      },
      styles: {
        valign: "middle",
        fontSize: 8,
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 1,
      },
      tableWidth: 120,
    });
  }
  if(comment) {
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text(comment, marges.margin_left + 70, marges.margin_top + marges.l_h + 150);
  }
  const pageCount = doc.internal.getNumberOfPages();
  columns = [];
  columns.push({ dataKey: "lib" });
  columns.push({ dataKey: "val" });
 
  if (pageCount>1)
  for (var i = 2; i <= pageCount; i++) {   
    doc.setPage(i);
    doc.autoTable({
      margin: { left: marges.margin_left },
      startY:marges.margin_top + marges.l_h+(info ? 13 : 8), 
      body: tier,
      columnStyles: {
        0: { halign: "left", cellWidth: 25 },
        1: { halign: "left", cellWidth: "auto" },     
      },
      columns: columns,
      theme: "plain",
      styles: {
        valign: "middle",
        fillColor: [255, 255, 255],
        textColor: [128, 128, 128],
        cellPadding: 0,
      },
      tableWidth: 60,
    });
    doc.autoTable({
      margin: { left: marges.margin_left+95 , },
      startY:marges.margin_top + marges.l_h+(info ? 5 : 0), 
      body: doctype,
      
      columnStyles: {
        0: { halign: "left", cellWidth: 50 },
        1: { halign: "left", cellWidth: "auto" },     
      },
      columns: columns,
      theme: "plain",
      styles: {
        valign: "middle",
        fillColor: [255, 255, 255],
        textColor: [128, 128, 128],
        cellPadding: 0,
      },
      tableWidth: "auto",
    });
  }
  addHeaders(doc, store,cmpt,info,company);
  addFooters(doc, store);
  doc.setProperties({ title: title });
  doc.output("pdfobjectnewwindow");
}